import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AfterLogin, ProtectedRoute, RequireAuthStudent, StudentLogout } from '../middleware/CheckRole';
import { Login, SignUp, LoginLayouts, Layout, Dashboard, Profile, EditProfile, Notification, Naplan, Assessment, Consulting, Homework,
     ETutoring, ClassList, ClassReview,SelfStudy,SelectiveHighSchool, MySchoolChoices, CsNormalClasses, Cart, Checkout, Payment,
    EventDetail, WritingClass, Questionaire, Classes, AssesmentList, AssesmentDetail, LectureDetail, WebsitEnvDetail, Payments, LoginFreeAssesment, AssessmentFree,CaptchaComponent
} from '../components/pages/Index';


function CreateRoute() {
    return (
        <BrowserRouter>
            <Routes>
                    <Route path='/captcha/:token' element={<CaptchaComponent />}/>
                <Route element={<LoginLayouts />}>
                    <Route path="/join-member" element={<AfterLogin><SignUp /></AfterLogin>} />
                    <Route path='/payments' element={<Payments />}/>
                    <Route path="/login" element={<AfterLogin><Login /></AfterLogin>} />
                    <Route path="/loginFreeAssesment" element={<AfterLogin><LoginFreeAssesment /></AfterLogin>} />
                </Route>
                <Route path="/my-e-test/questionaire/:id" element={<Questionaire />} />
                <Route path="/" element={<ProtectedRoute><Layout /></ProtectedRoute>} > 
                    {/* <Route element={<RequireAuthStudent />}> */}
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/eventDetail" element={<EventDetail />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/edit-profile" element={<EditProfile />} />
                    <Route path="/notification" element={<Notification />} />
                    <Route path="/my-e-test/naplan" element={<Naplan />} />
                    <Route path="/my-e-test/assessment" element={<Assessment />} />
                    <Route path="/my-e-test/assessmentfree" element={<AssessmentFree />} />
                    <Route path="/my-e-test/consulting" element={<Consulting />} />
                    <Route path="/my-e-test/homework" element={<Homework />} />
                    <Route path="/my-e-test/etutoring" element={<ETutoring />} />
                    <Route path="/my-e-test/classlist" element={<ClassList />} />
                    <Route path="/Classes/classes-list" element={<Classes />} />
                    <Route path="/Classes/lecture-list/:id/:c_id" element={<LectureDetail />} />
                    {/* Assesment */}
                    <Route path="/Classes/assesment-list" element={<AssesmentList />} />
                    <Route path="/my-e-test/assesment-detail/:id" element={<AssesmentDetail />} />
                    <Route path="/my-e-test/selfStudy" element={<SelfStudy />} />
                    <Route path="/my-e-test/class-review" element={<ClassReview />} />
                    <Route path="/my-academic-info/selectiveHighSchool" element={<SelectiveHighSchool />} />
                    <Route path="/my-academic-info/mySchoolChoices" element={<MySchoolChoices />} />
                    <Route path="/study-rooms/csNormalClasses" element={<CsNormalClasses />} />
                    <Route path="/carts/cart" element={<Cart />} />
                    <Route path="/carts/checkout" element={<Checkout />} />
                    <Route path="/carts/payment" element={<Payment />} />
                    <Route path="/websiteEnv-detail" element={<WebsitEnvDetail />} />
                    
                </Route>
                <Route path="/logout" element={<StudentLogout />} />
                {/* </Route> */}
            </Routes>
        </BrowserRouter>
    )
}

export default CreateRoute;
