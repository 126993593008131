export const FreevalidateStep = (step, data) => {
    const validationErrors = {};

    const namePattern = /^[a-zA-Z]+$/;  
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/; 
    switch (step) {
        case 1:
            // First name validation with pattern
            if (!data.first_name) {
                validationErrors.first_name = 'Enter your First Name.';
            } else if (!namePattern.test(data.first_name)) {
                validationErrors.first_name = 'First name can only contain letters.';
            }

            // Last name validation with pattern
            if (!data.last_name) {
                validationErrors.last_name = 'Enter your Last Name.';
            } else if (!namePattern.test(data.last_name)) {
                validationErrors.last_name = 'Last name can only contain letters.';
            }

            // Grade validation
            if (!data.grade) {
                validationErrors.grade = 'Select an option.';
            }

            // Email validation with pattern
            if (!data.email) {
                validationErrors.email = 'Enter your Email.';
            } else if (!emailPattern.test(data.email)) {
                validationErrors.email = 'Enter a valid Email.';
            }

            // Mobile number validation
            if (!data.mobile) {
                validationErrors.mobile = 'Enter your Phone Number.';
            }

            // Date of birth validation
            if (!data.date_of_birth) {
                validationErrors.date_of_birth = 'Enter your DOB.';
            } else {
                const dobPattern = /^\d{4}-\d{2}-\d{2}$/; // Ensure the DOB format is YYYY-MM-DD
                if (!dobPattern.test(data.date_of_birth)) {
                    validationErrors.date_of_birth = 'Date of Birth must be in YYYY-MM-DD format.';
                } else {
                    const dob = new Date(data.date_of_birth);
                    const today = new Date();

                    // Check if the date is in the future
                    if (dob > today) {
                        validationErrors.date_of_birth = 'Date of Birth cannot be in the future.';
                    } else {
                        // Check if the user is at least 20 years old
                        const minAgeDate = new Date();
                        minAgeDate.setFullYear(minAgeDate.getFullYear() - 20);

                        if (dob > minAgeDate) {
                            validationErrors.date_of_birth = 'You must be at least 20 years old.';
                        } else {
                            // No errors for date of birth
                            validationErrors.date_of_birth = ''; 
                        }
                    }
                }
            }

            // School name validation
            if (!data.school) {
                validationErrors.school = 'Enter your School Name.';
            }

            break;

        case 2:
            // Email (userId) validation
            if (!data.email) {
                validationErrors.email = 'Enter your UserId.';
            } else if (!emailPattern.test(data.email)) {
                validationErrors.email = 'Enter a valid UserId.';
            }

            // Password validation
            if (!data.password) {
                validationErrors.password = 'Password is required.';
            } else if (data.password.length < 6) {
                validationErrors.password = 'Password must be at least 6 characters long.';
            }
            break;

        default:
            break;
    }

    return validationErrors;
};
